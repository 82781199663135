import { FilterItem } from '@mdb/devcenter-components';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { getFiltersFromQueryStr } from './utils';

const useFilter = (
    callback: () => void,
    filterItems?: { key: string; value: FilterItem[] }[]
) => {
    const router = useRouter();
    const [filters, setFilters] = useState<FilterItem[]>([]);

    const onFilter = useCallback(
        (filters: FilterItem[]) => {
            setFilters(filters);
            callback();
        },
        [callback]
    );

    useEffect(() => {
        if (router?.isReady) {
            if (filterItems) {
                const allNewFilters = getFiltersFromQueryStr(
                    router.query,
                    filterItems
                );
                setFilters(allNewFilters);
            }
        }
    }, [filterItems, router?.isReady, router.query]);

    return {
        filterProps: {
            filters,
            onFilter,
        },
        clearFilters: () => setFilters([]),
    };
};

export default useFilter;
